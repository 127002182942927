$primary: #43265e;
$secondary: #bc3e31;
$darkCopy: #2b3136;
$copy: #3f484f;
$lightCopy: #626f7a;
$body: #eae8ea;
$boxShadow: 0px 2px 8px 0px rgba(darken($primary, 30%), 0.3);
$cardBottom: #e8e6e1;
$inputBorder: lighten($lightCopy, 40%);
$gutter: 30px;
$borderRadius: 8px;

.date-range-select.open {
    &::before {
        content: "";
        display: block;
        height: 100vh;
        width: 100vw;
        background-color: rgba(50, 50, 50, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 20;
    }
}
.date-range-select {
    & > .form-group .form-control {
        cursor: pointer;
    }
    .time-select {
        .form-control {
            box-shadow: none;
            border: 2px solid $inputBorder;
        }
    }
}
.date-range-popup {
    position: fixed;
    z-index: 21;
    width: 50vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.4s ease;
    display: none;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
}
.time-selector {
    display: flex;
    width: 100%;
    align-items: center;
    background-color: $body;
    border-bottom-left-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
}
.time-selector .time-group {
    flex: 1;
}
.time-selector .time-group .time-group-title {
    text-transform: uppercase;
    margin-bottom: 5px;
    font-size: 14px;
}
.time-selector .time-group .time-group-value {
    font-size: 18px;
    font-weight: bold;
}
.date-range-popup .item {
    padding: 30px;
}
.date-range-select.open .date-range-popup {
    display: block;
    transform: translate(-50%, -50%) scale(1);
    &--time {
        display: flex;
        width: 60vw;
        &.date-range-popup--non-range {
            width: 50vw;
            .date-range-popup_main {
                display: flex;
                flex-direction: column;
                .item.time-selector {
                    margin-top: auto;
                }
            }
        }
        .date-range-popup {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        .date-range-popup_main {
            flex: 2;
        }
        .time-select {
            flex: 1;
            padding: $gutter;
            align-items: center;
            display: flex;
            background-color: lighten($lightCopy, 30%);
            border-top-right-radius: $borderRadius;
            border-bottom-right-radius: $borderRadius;
            .time-select-group {
                flex: 1;
            }
            .form-group {
                background-color: transparent;
                box-shadow: none;
                .form-control {
                    border: 0;
                }
            }
        }
    }
}
.date-range-header {
    display: flex;
    align-items: center;
    position: relative;
}
.date-range-header button {
    border: 0;
    background-color: transparent;
    outline: 0;
    position: absolute;
    top: 0;
    &:first-child {
        left: 0;
    }
    &:last-child {
        right: 0;
    }
    &:hover {
        cursor: pointer;
        svg {
            fill: $primary;
        }
    }
}
.date-range-header button svg {
    height: 32px;
    width: 32px;
    fill: gray;
}
.date-range-header .month-title {
    font-size: 18px;
    font-weight: bold;
    flex: 1;
    text-align: center;
}
.months {
    display: flex;
}
.month {
    flex: 2;
    flex-wrap: wrap;
}
.time-selector {
    flex: 1;
}
.month:first-child {
    margin-right: 30px;
}
.month .title {
    font-size: 14px;
    font-weight: bold;
    padding: 10px;
    text-align: center;
}
.month .cell-row, .month .title-row {
    display: flex;
    overflow: hidden;
}
.month .title, .month .cell {
    flex-basis: calc(100% / 7);
    text-align: center;
    padding: 10px;
    span {
        display: inline-flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        height: 32px;
        width: 32px;
        border-radius: 100%;
        position: relative;
        z-index: 2;
        transition: all 0.4s ease;
    }
}
.month .cell {
    &::before, &::after {
        transition: all 0.4s;
    }
    &:hover {
        cursor: pointer;
        span {
            background-color: lighten($secondary, 30%);
            color: $secondary;
        }
    }
    &.empty {
        span {
            background-color: transparent;
            color: transparent;
        }
        cursor: auto;
    }
}
.month .cell.selected span {
    background-color: lighten($secondary, 30%);
    color: $secondary;
}
.cell.between + .cell.selected, .cell.selected + .cell.between {
    position: relative;
    &::after {
        content: "";
        display: block;
        position: absolute;
        height: 20px;
        width: 50%;
        animation: expand50 0.1s ease-out;
        animation-fill-mode: both;
        background-color: lighten($lightCopy, 25%);
        z-index: 1;
        top: 17px;

    }
}
.cell.between + .cell.selected {
    &::after {
        left: 0;
    }
}
.cell.selected + .cell.between {
    &::after {
        left: -50%;
    }
}
.cell.between {
    position: relative;
    &::before {
        content: "";
        display: block;
        position: absolute;
        height: 20px;
        width: 100%;
        animation: expand 0.1s ease-out;
        animation-fill-mode: both;
        background-color: lighten($lightCopy, 25%);
        z-index: 1;
        left: 0;
        top: 17px;
    }
}
.cell.selected + .cell.selected {
    position: relative;
    &::before {
        content: "";
        display: block;
        position: absolute;
        height: 20px;
        width: 100%;
        animation: expand 0.1s ease-out;
        animation-fill-mode: both;
        background-color: lighten($lightCopy, 25%);
        z-index: 1;
        left: -50%;
        top: 17px;
    }
}
@keyframes expand {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}
@keyframes expand50 {
    from {
        width: 0;
    }
    to {
        width: 50%;
    }
}
