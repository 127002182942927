body.auth .bg {
    background-color: #43265e;
    height: 50vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.logo > a {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.logo svg {
    height: 38px;
    width: auto;
    fill: white;
}

.toasted-ef {
    padding: 6px 20px !important;
    font-weight: 300;
}
